jQuery(function ($) {
  /*** Header fixed ***/
  $(window).on( 'scroll', function(){
    var scrollPixel = $(window).scrollTop();
    if (scrollPixel < 100) {
      $('header').removeClass('headerFix');
    } else {
      $('header').addClass('headerFix');
    };
  });
  headerResize();  
});

/*** Mobile Header toggle ***/
function headerResize() {
  var screen_width = $(window).width();
  if (screen_width < 991) {
    $('.navbar-toggler').off('click');
    $('.navbar-toggler').on('click', function () {
      $('header nav').toggleClass('navbar-show');
      $('#divNavbar').slideToggle(200);
    });

    $("#divNavbar > ul > li.dropdown > .dropdown-menu").hide();
    $('#divNavbar > ul > li.dropdown > .arrow').off('click');
    $('#divNavbar > ul > li.dropdown > .arrow').on('click', function (event) {
      event.preventDefault();
      $(this).closest('#divNavbar > ul > li.dropdown').find('> .dropdown-menu').slideToggle('slow');
      $(this).parent().toggleClass('open-dropdown');
    });
  } else {
    $('header nav').removeClass('navbar-show');
    $('.navbar-toggler').attr('aria-expanded', 'false');
    $("#divNavbar > ul > li.dropdown > .dropdown-menu").removeAttr("style");
    $("header .navbar-collapse").removeAttr("style").removeClass('show');
  }
}

$(window).on( 'resize', function(){
  headerResize();
});


const myDiv = document.getElementById("homeCol1");
const myDiv2 = document.getElementById("homeCol2");
const windowHeight = $(window).innerHeight() - $('header').innerHeight() - $('footer').innerHeight();
myDiv.style.setProperty("height", `${windowHeight}px`);
myDiv2.style.setProperty("height", `${windowHeight}px`);
